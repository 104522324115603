import React from "react";
import Nav from "./Nav";
import { HiOutlineMail } from "react-icons/hi";
import { Link } from "gatsby";

const Layout = ({ children, location }) => {
  return (
    <>
      <div className="flex">
        <div className="flex-1 w-full h-full">
          <Nav location={location} />
          <div className="bg-[url('../images/headerbg.webp')] bg-repeat-y bg-top pt-20" 
          style={{ paddingTop: 97 }}>
            {children}
          </div>
        </div>
        <div className="w-[80px] h-full bg-primary">
          <div className="w-[80px] fixed top-0 h-screen bg-primary ">
            <div className="w-full h-full flex flex-col justify-end items-center">
              <div className="text-2xl mb-10 text-white">
                <Link to="/contact">
                  <HiOutlineMail />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Layout;
