import React from "react";
import { Link } from "gatsby";
import { GiHamburgerMenu } from "react-icons/gi";
import logo from "../../images/logo.webp";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
const Nav = ({ location }) => {
  const { pathname } = location;
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };
  return (
    <div className="w-full min-h-[97px] flex bg-transparent absolute top-0">
      <div className="flex justify-between">
        <span className="block md:hidden pt-[20px] pl-[15px]">
          <Link to="/">
            <a>
              <img src={logo} alt="logo" className="w-[120px]" />
            </a>
          </Link>
        </span>
        <span onClick={toggleDrawer} className="block md:hidden">
          <GiHamburgerMenu className="text-white w-[30px] h-[30px] cursor-pointer absolute right-[20px] top-[20px] z-50" />
        </span>
      </div>
      <div className="main-container my-auto hidden md:block">
        <div className="flex items-end">
          <div className="flex-1 flex ">
            <Link to="/">
              <a>
                <img src={logo} alt="logo" />
              </a>
            </Link>
          </div>
          <div className="flex-[2] flex">
            <ul className="flex gap-8 ml-12">
              {navLinks.map(({ id, text, path }) => {
                return (
                  <li key={id}>
                    <Link to={path}>
                      <a
                        className={`${
                          pathname == path ? "text-primary" : "text-dark"
                        } capitalize`}
                      >
                        {text}
                      </a>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
      <div className="block md:hidden">
        <Drawer
          open={isOpen}
          onClose={toggleDrawer}
          direction="left"
          className=""
        >
          <div>
            <div className=" px-3 pt-5 mb-10 ">
              <Link to="/">
                <a>
                  <img src={logo} alt="logo" className="w-[120px]"/>
                </a>
              </Link>
            </div>
            <ul className="flex flex-col gap-y-4 px-6 ">
              {navLinks.map(({ id, text, path }) => {
                return (
                  <li key={id}>
                    <Link to={path}>
                      <a
                        className={`${
                          pathname == path
                            ? "text-primary "
                            : "text-dark transition-all hover:text-primary"
                        } capitalize`}
                      >
                        {text}
                      </a>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </Drawer>
      </div>
    </div>
  );
};

export default Nav;

const navLinks = [
  { id: 1, text: "Home", path: "/" },
  { id: 2, text: "services", path: "/services" },
  { id: 3, text: "about", path: "/about" },
  { id: 4, text: "contact", path: "/contact" },
];
